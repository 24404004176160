<template>
  <div class="">
    <form class="w-full" @submit.prevent="onSubmit">
      <div class="container mx-auto py-6 px-4">
        <div class="flex justify-between">
          <h2 class="text-2xl font-bold mb-6 pb-2 tracking-wider uppercase">
            Float Replenishment
          </h2>
        </div>

        <div class="flex flex-wrap justify-between mb-8">
          <div class="w-full md:w-1/3 mb-2 md:mb-0">
            <label
              class="
                text-gray-800
                block
                mb-1
                font-bold
                text-sm
                uppercase
                tracking-wide
              "
              >To:</label
            >
            <span
              class="
                mb-1
                appearance-none
                block
                rounded
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-blue-500
              "
              >{{ name }}
            </span>
            <span
              class="
                mb-1
                appearance-none
                rounded
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-blue-500
              "
              type="date"
              >{{ date }}</span
            >
          </div>
          <div class="w-full md:w-1/3">
            <label
              class="
                text-gray-800
                block
                mb-1
                font-bold
                text-sm
                uppercase
                tracking-wide
              "
              >Bank Deposit Details:</label
            >
            <span
              class="
                mb-1
                appearance-none
                block
                rounded
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-blue-500
              "
              >{{ bank }}
            </span>

            <span
              class="
                mb-1
                appearance-none
                block
                rounded
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-blue-500
              "
              type="text"
            >
              {{ bankSlip }}</span
            >
          </div>
        </div>

        <div class="flex -mx-1 border-b py-2 items-start">
          <div class="flex-1 px-1">
            <p class="text-gray-800 uppercase tracking-wide text-sm font-bold">
              Description
            </p>
          </div>

          <div class="px-1 w-52 text-right">
            <p class="leading-none">
              <span
                class="
                  block
                  uppercase
                  tracking-wide
                  text-sm
                  font-bold
                  text-gray-800
                "
              >
                Balance Before</span
              >
              <span class="font-medium text-xs text-gray-500">(GMD)</span>
            </p>
          </div>

          <div class="px-1 w-52 text-right">
            <p class="leading-none">
              <span
                class="
                  block
                  uppercase
                  tracking-wide
                  text-sm
                  font-bold
                  text-gray-800
                "
                >Allocate Amount</span
              >
              <span class="font-medium text-xs text-gray-500">(GMD)</span>
            </p>
          </div>
        </div>
        <div v-for="(allocation, i) in allocations" :key="i">
          <div class="flex -mx-1 py-2 border-b">
            <div class="flex-1 px-1">
              <p class="text-gray-800">{{ allocation.account }}</p>
            </div>

            <div class="px-1 w-52 text-right">
              <p class="text-gray-800">
                {{ formatPrice(allocation.balance_before) }}
              </p>
            </div>

            <div class="w-52 text-right">
              <span class="text-right"
                >{{ formatPrice(allocation.balance_allocated) }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex">
          <p
            name=""
            class="mt-4 p-2"
            cols="50"
            rows="5"
            placeholder="Enter remarks here"
          >
            {{ remarks }}
          </p>

          <div class="py-2 ml-auto mt-5 w-full sm:w-2/4 lg:w-1/3">
            <div class="py-2 border-t border-b">
              <div class="flex justify-between">
                <div class="text-xl text-gray-600 text-right flex-1">
                  Total Amount
                </div>
                <div class="text-right w-40">
                  <div class="text-xl text-gray-800 font-bold">
                    {{ formatPrice(balanceToAllocate) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
// import BranchSales from "../../components/Charts/BranchSales";
// import moment from  'moment'

export default {
  middleware: "auth",
  layout: "default",

  data: () => ({
    allocations: [],
    name: "Nawec",
    date: "",
    bankSlip: "",
    bank: "",
    amount: 0,
    remarks: "",
    isLoading: false,

    form: new Form({
      name: "Nawec",
      date: "",
      bankSlip: "",
      bank: "",
      amount: 0,
      // allocations: [],
      remarks: "",
    }),
  }),

  mounted() {
    console.log("Loading Balances");
    this.loadData();
  },

  computed: {
    balanceToAllocate() {
      let sum = 0;
      this.allocations.forEach((e) => {
        sum += e.balance_allocated;
      });

      return sum;
    },
  },

  methods: {
    async loadData() {
      console.log("this is route", this.$route.params.id);
      const routeId = this.$route.params.id;

      try {
        const { data } = await axios.get(`/obtain_float/${routeId}`);

        console.log("Response", data);
        console.log("Response one", data.data.bank_name);

        this.bank = data.data.bank_name;
        this.bankSlip = data.data.slip_number;
        this.date = data.data.date;
        this.remarks = data.data.remarks;
        this.allocations = data.data.allocations;
        data.data.allocations.map((a) => console.log("tetsttss", a.account));
        // console.log('all one', data.data.allocations.map(a => a))

        let list = [];

        this.form.allocations = list;

        return true;
      } catch (e) {
        console.log("Error", e);
        return false;
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>